
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import Newsletter from "@/components/common/Newsletter.vue";
import Content from "@/components/common/Content.vue";
import { DataDownloader, get } from "@/api";
import i18n from "@/i18n";
import { ChallengeShortInfo } from "@/interfaces/challenge";
const pageKey = "business";

@Component({
  components: {
    Newsletter,
    Content,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class Business extends Vue {
  challenges = new DataDownloader<ChallengeShortInfo[]>(() => {
    if (this.$store.getters.isLoggedIn) return get.challengeShortInfo();
    return get.challengePreview();
  });

  get mainChallenge() {
    return this.challenges.data?.find((challenge) => challenge.id === 7);
  }

  proceedToPayment() {
    this.$store.commit("setPayment", this.mainChallenge as ChallengeShortInfo);
    this.$router.push("/payment-challenge");
  }
}
